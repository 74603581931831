@use "./Mixins" as m;

.loginForm {
  @include m.flex(column, center, center);
  height: 100%;
  width: 100%;
  background-color: #fff;
  overflow-y: scroll;
}

.loginFields {
  display: grid;
  grid-template-columns: 0.75fr 1fr;
  max-height: 200px;
  height: 100%;
  max-width: 550px;
  width: 100%;
  column-gap: 2rem;
}

.loginFormTitle {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin: 0;
  padding: 0;
  align-self: flex-end;
  justify-self: flex-end;
}

.loginFormValue {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  margin: 0;
  padding: 0;
  align-self: flex-end;
  justify-self: flex-start;
  border-radius: 0.5rem;
  width: 100%;
}
