@use "./Mixins" as m;

.loadingSpinner {
  font-size: 1.5em;
}

.mainScreen {
  height: 100vh;
  height: 100dvh;
  max-width: 100vw;
  max-width: 100dvw;
  width: 100%;
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 6rem auto 6rem;
}

.mainHeader {
  background-color: #ccc;
  background: linear-gradient(#bbb, #aaa, #bbb);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.4);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
  justify-content: center;
  align-items: center;
}

.logo {
  @include m.flex(row, flex-start, center);
  height: 100%;
  width: 100%;
  padding-left: 1rem;

  img {
    object-fit: cover;
    max-height: 5.5rem;
    justify-self: flex-start;
  }
}

.mainBody {
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 12rem);
  display: grid;
  grid-template-columns: min-content 1fr 3fr;
  column-gap: 1rem;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  padding: 5rem 20rem;
}

.mainFooter {
  background: linear-gradient(#bbb, #aaa, #bbb);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
}

.mainLogin {
  @include m.flex(column, center, center);
  height: 100%;
  width: 100%;
  background-color: #fff;
  overflow-y: scroll;
}

.container {
  &.login {
    display: grid;
    grid-template-rows: 4rem 1fr 4rem;
    row-gap: 1rem;
    max-height: 200px;
    height: 100%;
    max-width: 550px;
    width: 100%;
    background: linear-gradient(#ddd, #bbb, #ddd);
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.4);
    padding: 1rem;
  }

  &.reportTypes {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    row-gap: 1rem;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
  }

  &.results {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    row-gap: 0.5rem;
    height: 100%;
    max-height: 100%;
    min-width: 32dvw;
    overflow-y: scroll;
    border: 0.2rem solid #bbb;
    border-radius: 1rem;
    padding: 0.5rem;
    box-shadow: 0 0 0.1rem 0 rgba(0, 0, 0, 0.4);

    &.message {
      justify-content: center;
      align-items: center;
      grid-template-rows: 1fr;
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
}

.header {
  &.login {
    @include m.flex(column, center, center);
  }
}

.footer {
  &.login {
    @include m.flex(column, center, center);
  }
}

.body {
  &.login {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 1rem;
  }
}

.title {
  &.login {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
  }
}

.button {
  &.login {
    border-radius: 0.5rem;
    padding: 0.25rem 1rem;
  }
}

.name {
  &.bold {
    font-weight: 500;
  }
  &.people {
    border-left: #555 solid 0.1rem;
    padding: 0 1rem;
    height: 90%;
    align-content: center;
    display: grid;
  }
  &.facility {
    border-left: #555 solid 0.1rem;
    padding: 0 1rem;
    height: 90%;
    align-content: center;
    display: grid;
  }
  &.course {
    border-left: #555 solid 0.1rem;
    padding: 0 1rem;
    height: 90%;
    align-content: center;
    display: grid;
  }
  &.reportTypes {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
  }

  &.header {
    font-size: 2.5rem;
    font-weight: 700;
    color: #222;
    text-shadow: 0 0 0.5rem #fff;
  }
}

.checkboxContainer {
  display: grid;
  height: 100%;
  width: 100%;
  padding: 0 0.7rem;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  text-align: center;
  // .checkbox {
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  //   column-gap: 1rem;
  //   justify-content: center;
  //   align-items: center;
  //   text-align: center;
  //   cursor: pointer;
  // }
}

.resetFiltersButton {
  display: grid;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 3rem;
  right: 1rem;
  cursor: pointer;
}
