body ::-webkit-scrollbar {
  background-color: rgba(255, 255, 255, 0);
  width: 0.9rem;
  height: 1rem;
}

body ::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border: 0.2rem solid #bbb;
  border-radius: 0.5rem;
  background-clip: content-box;
  min-height: 2rem;
}

body ::-webkit-scrollbar-corner {
  display: none;
}

body ::-webkit-scrollbar-track {
  background: transparent;
  background-clip: content-box;
}

.filterContainer {
  display: grid;
  height: 100%;
  grid-template-rows: min-content 1fr 7rem;
  border: 0.2rem solid #bbb;
  border-radius: 1rem;
  padding: 0;
  box-shadow: 0 0 0.1rem 0 rgba(0, 0, 0, 0.4);
  overflow-y: hidden;
  position: relative;
  background-color: rgba(60, 60, 60, 0.1);
  background-position: center;
  background-repeat: no-repeat;
}

.filters {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 3rem;
  grid-auto-rows: min-content;
  row-gap: 1rem;
  justify-content: center;
  align-items: flex-end;
  overflow-y: scroll;
  padding: 1rem 1rem 1rem 2rem;
  select {
    width: 100%;
    height: 2rem;
    border: 0.1rem solid #000;
    border-radius: 0.5rem;
    font-size: 1.25rem;
    font-weight: 700;
    color: #000;
    background: linear-gradient(#fff, #f5f5f5);
    padding: 0.1rem 0.5rem;
  }
}

.filterTabs {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  height: 3rem;
  border-bottom: 0.2rem solid #bbb;
  user-select: none;
  .tab {
    display: grid;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 600;
    background: linear-gradient(#eee, rgb(246, 221, 158));
    border-left: 0.2rem solid #bbb;
    cursor: pointer;
    &:first-child {
      border-left: 0;
    }
    &:hover {
      background: linear-gradient(#eee, rgb(216, 194, 140));
    }
    &:active {
      background: linear-gradient(rgb(246, 221, 158), #eee);
    }

    &.selectedTab {
      background: linear-gradient(#eee, #99f5c7);
      &:hover {
        background: linear-gradient(#eee, #88dab1);
      }
      &:active {
        background: linear-gradient(#88dab1, #eee);
      }
    }
  }
}

.filterItem {
  min-width: 100%;
  min-height: 2.5rem;
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  &.checkbox {
    width: 100%;
  }
  &.text {
    width: 100%;
    height: 100%;
    max-height: 3.5rem;
  }
  &.date {
    justify-self: flex-start;
    display: grid;
    row-gap: 1rem;
    cursor: pointer;
    width: 100%;
    .itemInput {
      grid-template-columns: 1fr;
      width: 100%;
    }
  }

  .itemInput {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .itemTitle {
    text-align: left;
    width: 100%;
  }
}

.itemTitle {
  font-size: 1.25rem;
  font-weight: 700;
  color: #000;
}

.itemInput {
  &.text {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 1fr;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;

    input {
      height: 100%;
    }
  }

  &.date {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    justify-content: center;
    align-items: center;
  }

  &.singleDate {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 1rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &.checkbox {
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    column-gap: 1rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    input {
      width: 100%;
      justify-self: flex-end;
    }
  }

  input {
    width: 100%;
    height: 2rem;
    border: 0.1rem solid #000;
    border-radius: 0.5rem;
    font-size: 1.25rem;
    font-weight: 700;
    color: #000;
    background: linear-gradient(#fff, #f5f5f5);
    padding: 0.1rem 0.5rem;
  }
}

.buttonContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 3rem 4rem;
  border-top: 0.2rem solid #bbb;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  padding: 0 0.5rem;
  column-gap: 0.5rem;
  width: 100%;

  .emailEntry {
    font-size: 1.2rem;
    font-weight: 600;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column: span 3;
    padding: 0 1rem;
  }

  &.modal {
    grid-template-rows: 1fr;
    border-top: 0;
    width: 30rem;
  }
}

.button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  height: 2.5rem;
  width: 100%;
  background: linear-gradient(#fff, #f5f5f5);
  border: 0.2rem solid #bbb;
  border-radius: 0.5rem;

  &:hover {
    background: linear-gradient(#f5f5f5, #ddd);
  }
  &:active {
    background: linear-gradient(#ddd, #f5f5f5);
  }

  &.disabled {
    background: linear-gradient(#ddd, #bbb);
    color: #444;
    cursor: default;

    &:hover {
      background: linear-gradient(#ddd, #bbb);
    }
    &:active {
      background: linear-gradient(#ddd, #bbb);
    }
  }
}

.buttonText {
  font-size: 1rem;
  font-weight: bold;
}

.selectableRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0.2rem solid #bbb;
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  cursor: pointer;
  background: linear-gradient(#fff, #f5f5f5);
  box-shadow: 0 0 0.1rem 0 rgba(0, 0, 0, 0.4);

  &:nth-child(2n) {
    background: linear-gradient(#f5f5f5, #ddd);
  }

  &:hover {
    background: linear-gradient(#ddd, #b5b5b5);
  }
  &:active {
    background: linear-gradient(#b5b5b5, #ddd);
  }

  &.resultItem {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    column-gap: 1rem;
    justify-content: space-evenly;
    align-items: center;
    justify-self: center;
    align-self: center;
    min-height: 3rem;
    padding: 0 1rem;
    text-align: left;
  }

  &.person {
    grid-template-columns: 3rem 15rem 15rem 15rem 15rem 1fr;

    &.hold {
      background: linear-gradient(#f8cfcf, #f6c2c2);
      &:hover {
        background: linear-gradient(#f6c2c2, #f8a7a7);
      }
      &:active {
        background: linear-gradient(#f8a7a7, #f6c2c2);
      }
    }

    &.noAamsn {
      background: linear-gradient(#f7f1c4, #f0f6b1);
      &:hover {
        background: linear-gradient(#f0f6b1, #cfd871);
      }
      &:active {
        background: linear-gradient(#cfd871, #f0f6b1);
      }
    }
  }

  &.selected {
    background: linear-gradient(#99f5c7, #bde2cd);

    &:nth-child(2n) {
      background: linear-gradient(#8de1b7, #afd0bd);
    }
  }

  &.facility {
    grid-template-columns: 3rem 1fr;
  }

  &.course {
    grid-template-columns: 3rem 31rem 15rem 15rem 1fr;
  }
}

.basicContainer {
  display: grid;
  justify-content: center;
  align-items: flex-start;
  flex-grow: 1;
  padding: 1rem;
}

.buttonsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
}

.basicButton {
  font-size: 1.25rem;
  font-weight: 700;
}

.sendEmailInput {
  font-family: inherit;
  font-size: 1.5rem;
  display: inline-block;
  width: 60%;
  text-align: center;
  margin-top: 0.5rem;
  height: 2rem;
  border: 0.1rem solid #000;
  border-radius: 0.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  color: #000;
  background: linear-gradient(#fff, #f5f5f5);
  padding-left: 0.5rem;
}

.fadedOut {
  opacity: 0.5;
}
