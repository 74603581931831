.modal {
  & .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0ß;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 900;
  }

  & .modalContent {
    position: fixed;
    display: grid;
    grid-template-rows: 5rem 1fr;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #eee;
    border-radius: 0.5rem;
    z-index: 1000;
    height: 75vh;
    height: 75dvh;
    min-width: 65vw;
    min-width: 65dvw;
    overflow: hidden;

    .close {
      display: grid;
      align-items: center;
      font-size: 3rem;
      font-weight: 700;
      color: #000;
      cursor: pointer;
    }
  }
}

.modalHeader {
  display: grid;
  grid-template-columns: 5rem 1fr 5rem;
  border-width: 0 0 0.1rem 0;
  border-style: solid;
  border-color: #000;
}

.modalBody {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 1rem 2rem 2rem;
  scrollbar-width: 1rem;
}

.modalBodyHeader {
  text-align: center;
}

.modalBodyContent,
.modalBodyRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  min-height: 2.5rem;
  padding: 1rem;
  font-size: 1.25rem;
  text-align: left;
  & .modalLabel {
    font-weight: 700;
  }
  & input[type="checkbox"] {
    width: 1.25rem;
    height: 1.25rem;
  }

  & .modalInput {
    width: calc(100%);
    height: 2.3rem;
    border: 0.2rem solid #058721;
    border-radius: 0.5rem;
    font-size: 1.25rem;
    color: #000;
    opacity: 1;
    background: linear-gradient(#fff, #f5f5f5);
    padding-left: 0.5rem;
  }

  & .modalInput[readonly] {
    background: transparent;
    border: none;
    color: #000;
    cursor: default;
    outline: none;
    padding-left: 0;
  }

  & .modalDate {
    width: 35%;
  }

  & .modalTextArea[readonly] {
    background: transparent;
    border: 0.1rem solid #000;
    color: #000;
    cursor: default;
    outline: none;
  }

  & .modalFullLengthColumn {
    grid-column: span 3;
    display: grid;
    grid-template-columns: 0.1fr 1fr;

    &.withButton {
      grid-template-columns: 0.35fr 2.65fr;
    }
  }
  & .largeModalDropdown {
    grid-column: span 2;
    grid-template-columns: 0.5fr 2fr;
  }
  & .largeInput {
    width: 100%;
  }

  .highlighted {
    background-color: #f0b563;

    &.button {
      background: linear-gradient(
        rgba(0, 0, 0, 0),
        #f5f5f5,
        #f5f5f5,
        rgba(0, 0, 0, 0)
      );
      background-color: #f0b563;

      &:hover {
        background: linear-gradient(
          rgba(0, 0, 0, 0),
          #ddd,
          #ddd,
          rgba(0, 0, 0, 0)
        );
        background-color: #f0b563;
      }

      &:active {
        background: linear-gradient(
          rgba(0, 0, 0, 0),
          #ccc,
          #ccc,
          rgba(0, 0, 0, 0)
        );
        background-color: #f0b563;
      }
    }
  }
}

.modalBodyColumn {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  // gap: 1rem;
  justify-content: start;
  align-items: center;

  &.withButton {
    grid-template-columns: min-content 1fr;

    .button {
      justify-self: flex-start;
    }
  }

  .modalLabel {
    border-bottom: 0.1rem solid #000;
  }
}

.modalBodyValue {
  padding: 0.3rem 0.5rem;
  width: fit-content;
  border: 0.1rem solid #000;
  border-radius: 0.5rem;
  min-height: 1.6rem;
  width: calc(100% - 1rem);
}

.modalCommentsRow {
  grid-template-columns: 1fr;
}

.modalCommentsColumn {
  grid-template-columns: 0.1fr 1fr;
}

.modalTextArea {
  display: inline-block;
  padding: 0.5rem;
  font-family: inherit;
  font-size: inherit;
  height: 50px;
}

input[readonly]::placeholder,
textarea[readonly]::placeholder,
.modalInput::placeholder {
  color: black;
  opacity: 1;
}

.modalButton {
  font-size: 1.25rem;
}
